<template>
  <b-card title="Reports ">
    <b-card-text>Reports.</b-card-text>
  </b-card>
</template>

<script>
import { BCard, BCardText } from "bootstrap-vue";

export default {
  components: {
    BCard,
    BCardText,
  },
};
</script>

<style></style>
